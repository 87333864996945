<template>
  <div class="main">
    <div class="main__hero" :class="`main__hero_${variant}`">
      <h1 class="main__title">Deeper</h1>
      <div class="main__icon">
        <svg v-if="(variant == 4) || (variant == 5)" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.583 12.498C0.553 11.404 0 10.177 0 8.188C0 4.688 2.457 1.551 6.03 0L6.923 1.378C3.588 3.182 2.936 5.523 2.676 6.999C3.213 6.721 3.916 6.624 4.605 6.688C6.409 6.855 7.831 8.336 7.831 10.177C7.831 11.1053 7.46225 11.9955 6.80587 12.6519C6.1495 13.3083 5.25926 13.677 4.331 13.677C3.258 13.677 2.232 13.187 1.583 12.498V12.498ZM11.583 12.498C10.553 11.404 10 10.177 10 8.188C10 4.688 12.457 1.551 16.03 0L16.923 1.378C13.588 3.182 12.936 5.523 12.676 6.999C13.213 6.721 13.916 6.624 14.605 6.688C16.409 6.855 17.831 8.336 17.831 10.177C17.831 11.1053 17.4623 11.9955 16.8059 12.6519C16.1495 13.3083 15.2593 13.677 14.331 13.677C13.258 13.677 12.232 13.187 11.583 12.498V12.498Z" fill="#BBA9E0"/> </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:v="https://vecta.io/nano"><path d="M32.246 15.616l1.178-.027c12.901-.219 8.601-19.174-5.204-14.161-10.189 3.698-8.299 20.708 2.602 23.502 4.821 1.233 10.189.356 6.19-1.014-3.424-1.15-6.19-4.465-5.999-7.176a1.25 1.25 0 0 1 1.233-1.123zM7.073.742c-12.408 4.766-7.368 25.583 6.41 26.487 3.588.247 4.52-.602 1.808-1.616-2.629-.986-5.944-5.643-6.3-8.792-.082-.63.411-1.15 1.041-1.15 3.616 0 5.122 0 7.231-2.903C22.44 5.591 15.565-2.517 7.073.742z"/></svg>
      </div>
      <p class="main__text">
        Opportunities don't happen, you create them
      </p>
    </div>
    <div class="main__content">
      <div class="main__grid">
        <GridTile v-for="test in tests" :key="test.id" :testid="test.id" :img="require('@/assets/img/main/grid/' + (!test.preview_img ? '4.png' : test.preview_img))" :title="test.name" :active="test.active == 'Y'" :completed="test.completed == 'Y'" :rating="test.rating" :time="test.pass_time" />
<!--        <GridTile :testid="3" :img="require('@/assets/img/main/grid/1-6.png')" title="Long awaited meeting" :active="true"/>
        <GridTile :testid="1" :img="require('@/assets/img/main/grid/2.png')" title="Frendship never dies"/>
        <GridTile :testid="2" :img="require('@/assets/img/main/grid/3.png')" title="Your career path"/>
        <GridTile :testid="0" :img="require('@/assets/img/main/grid/4.png')" title="Important exam"/>
        <GridTile :testid="4" :img="require('@/assets/img/main/grid/5.png')" title="Marriage" :active="false"/>
        <GridTile :testid="5" :img="require('@/assets/img/main/grid/6.png')" title="Romance is dead" :active="false" />
        <GridTile :testid="6" :img="require('@/assets/img/main/grid/7.png')" title="Long time no date" :active="false"/>
        <GridTile :testid="7" :img="require('@/assets/img/main/grid/8.png')" title="Unappreciated job" :active="false"/>-->
      </div>
     </div>
    <Panel />
  </div>
</template>

<script>
import GridTile from '../components/GridTile.vue';
import Panel from '../components/Panel.vue';

export default {
  components: { Panel, GridTile },
  data: ()=>({
    variant: 5,
    tests: {}
  }),
  async beforeCreate() {
    let result = await fetch(this.$store.state.serverUrl + '/main', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      }
    }).then(res => {
      if (!res.ok) throw ('main: not_ok')
      return res.json()
    }).catch(err => {
      console.error(err)
      return null
    })

    if (result.status == 'ok') {
      this.tests = result.tests
    }
    else {
      if (result.redirect_url) {
        this.$router.push(result.redirect_url)
        return
      }

      alert(result.message)
      return null
    }
  },
  mounted(){
    this.$store.commit('setMode', 1)
  }
}
</script>

<style lang="sass">
@import "@/sass/normalize.sass"
.main
  background: colors.$F7
  min-height: 100vh
  color: colors.$mainfont
  display: flex
  flex-direction: column
  &__title
    margin-top: 28px
    font-size: 44.8094px
  &__content
    flex-grow: 1
  &__icon
    width: 42px
    height: 28px
    margin: 0 auto
    svg
      fill: white
      width: 100%
  &__text
    font-family: fonts.$font4
    font-size: 18px
    line-height: 20px
    margin: 0 auto
    width: 220px
  &__hero
    width: 100%
    height: 320px
    margin-bottom: -52px
    padding-top: 17px
    text-align: center
    background-size: cover
    &_0
      background-image: url('~@/assets/img/main/bg0.jpg')
      .main__title,.main__icon
        margin-bottom: 17.5px
      .main__title
        font-family: fonts.$font4
        letter-spacing: normal
        font-weight: 400
        font-size: 42.4811px
        line-height: 56px
    &_1
      background-image: url('~@/assets/img/main/bg0.jpg')
      .main__title,.main__icon
        margin-bottom: 17.5px
      .main__title
        font-family: fonts.$font5
        line-height: 58px
        margin: 28px auto 15px
        letter-spacing: -0.05em
        background: linear-gradient(90deg, #8568C4 3.66%, #858AEF 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent  
    &_2
      background-image: url('~@/assets/img/main/bg2.jpg')
      .main__title,.main__icon
        margin-bottom: 17.5px
      .main__title
        font-family: fonts.$font7
        line-height: 52px
        margin: 28px auto 21px
        letter-spacing: -0.05em
        background: linear-gradient(90deg, #858AEF 3.66%, #AF65FF 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    &_3
      background-image: url('~@/assets/img/main/bg3.jpg')
      .main__title,.main__icon
        margin-bottom: 17.5px
      .main__title
        font-family: fonts.$font4
        font-weight: 400
        margin: 27px auto 15px
        line-height: 59px
        letter-spacing: -0.05em
        background: linear-gradient(90deg, #858AEF 3.66%, #AF65FF 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    &_4
      background-image: url('~@/assets/img/main/bg4.jpg')
      .main__title,.main__icon
        margin-bottom: 17.5px
      .main__title
        font-family: fonts.$font8
        font-weight: 700
        line-height: 53px
        text-align: center
        margin: 49px auto 10px
        background: linear-gradient(90deg, #858AEF 3.66%, #AF65FF 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
      .main__icon
        width: 18px
        margin-bottom: 0px
      .main__text
        font-family: fonts.$font1
        width: 200px
        margin-top: -2px
        font-size: 14px
        line-height: 17px
        color: #555454
    &_5
      background-image: url('~@/assets/img/main/bg5.jpg')
      @include since(800px)
        background-position-y: 20%
      & + .main__content
        .main__grid
          padding-top: 20px
          border-radius: 0
        &::before
          transform-origin: bottom center
          margin-top: -18.4%
          margin-bottom: -5px
          content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzc1IDM4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNLTcgNDguMjYzMkMtNyAzNC40MzU5IDIuNDUwODYgMjIuMzk5NiAxNS44ODM1IDE5LjExOTVMNTMuOTg0NyA5LjgxNTc1QzczLjY5MDggNS4wMDM4MiA5NC4zOTQzIDYuMjk4NjggMTEzLjM0NyAxMy41Mjg1TDEyNS4zMSAxOC4wOTE4QzE0NS44MTkgMjUuOTE1MSAxNjguMzMyIDI2Ljc3MzEgMTg5LjM3NyAyMC41MzM1TDI0MS40MjcgNS4xMDEyMUMyNjMuNDY1IC0xLjQzMjc5IDI4Ny4wODEgLTAuMTc1MjIgMzA4LjMgOC42NjIyNkwzNzMuMjc3IDM1LjcyNDFDMzg0LjU4OCA0MC40MzQ5IDM5MS44OTcgNTEuNTUwNiAzOTEuNzQgNjMuODAyMkwzNzkuNjI4IDEwMTBILTdWNDguMjYzMloiIGZpbGw9IiNGN0Y3RjciLz4KPC9zdmc+Cg==")
          width: 100%
          display: block
          @include since(600px)
            //transform: scaleY(0.5)
            margin-top: -13%
          @include since(900px)
            transform: scaleY(0.5)
          @include since(1200px)
            transform: scaleY(0.5)
            margin-top: -10.5%
      .main__title,.main__icon
        margin-bottom: 17.5px
      .main__title
        font-family: fonts.$font5
        line-height: 58px
        letter-spacing: -0.05em
        margin: 40px auto 14px
        background: linear-gradient(90deg, #8568C4 3.66%, #858AEF 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
      .main__icon
        width: 18px
        margin-bottom: 0px
      .main__text
        font-family: fonts.$font1
        width: 200px
        margin-top: -2px
        font-size: 14px
        line-height: 17px
        color: #555454
  &__grid
    display: grid
    padding: 40px 15px 60px
    border-radius: 30px 30px 0px 0px
    background: colors.$F7
    grid-template-columns: repeat(auto-fit, minmax(155px, 1fr) )
    grid-gap: 24px 9px
    gap: 24px 9px
</style>