<template>
  <div class="slidehead">
    <div class="slidehead__line">
      <Backlink @click="$emit('back')" :increased="true" class="slidehead__backlink"/>
      <ProgressBar :progress="progress" class="slidehead__progress"/>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Backlink from './Backlink.vue';
import ProgressBar from './ProgressBar.vue';

  export default {
    props: {
        progress: { type: Number, required: true }
    },
    components: { Backlink, ProgressBar }
}
</script>

<style lang="sass" scoped>
.slidehead
  padding: 60px 24px 0
  &__backlink
    margin-right: 18px
  &__line
    display: flex
    align-items: center
    margin-bottom: 5px
    margin-left: -9px
  &__progress
    flex-grow: 1
    margin-bottom: 4px
</style>