<template>
  <div class="tile" @click="toTest" :class="{ 'disabled': !active, 'completed': completed }">
    <div class="tile__img">
      <img v-if="img" :src="active ? img : require('@/assets/img/main/grid/not_active.png')">
    </div>
    <div class="tile__body">
      <div class="tile__title">{{ active ? title : 'Coming soon' }}</div>
      <div class="tile__line">
        <div class="tile__rating" v-if="active">{{ rating }}</div>
        <div class="tile__time" v-if="active">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: { type: String },
    title: { type: String },
    rating: { type: String, default: '4,7' },
    time: { type: String, default: '10 min' },
    testid: { type: Number },
    active: { type: Boolean, default: true },
    completed: { type: Boolean, default: false }
  },
  methods: {
    toTest() {
      if (this.testid !== undefined && this.active)
        this.$router.push('/test/' + this.testid)
    }
  }
}
</script>

<style lang="sass" scoped>
.tile
  display: flex
  flex-direction: column
  border-radius: 15px
  overflow: hidden
  cursor: pointer
  &__img
    height: 120px
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__body
    flex-grow: 1
    background: colors.$EB
    padding: 12px 16px
  &__title
    font-family: fonts.$font2
    font-weight: 500
    font-size: 14px
    line-height: 17px
    margin-bottom: 8px
  &__line
    font-family: fonts.$font1
    display: flex
    color: colors.$grayfont
    font-weight: 500
    font-size: 12px
    line-height: 15px
    >*:not(:last-child)
      margin-right: 12px
  &__rating
    display: flex
    &::before
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuODk2NDkgMS4zNzMzMkM1LjcxOTg1IDEuMDY4OTIgNS4yODAyNiAxLjA2ODkyIDUuMTAzNjMgMS4zNzMzMkwzLjg1OTY5IDMuNTE3MDlDMy43NzI0MyAzLjY2NzQ2IDMuNjIxNjIgMy43NzAwOCAzLjQ0OTcyIDMuNzk2MDRMMS4xMTg0OSA0LjE0ODE0QzAuNzI2OTQzIDQuMjA3MjggMC41ODk1OTkgNC43MDE2NSAwLjg5NDUzMSA0Ljk1NDI4TDIuNjMxMiA2LjM5MzA5QzIuNzkxOTUgNi41MjYyNyAyLjg2NzU0IDYuNzM2MzYgMi44Mjg0OSA2Ljk0MTQzTDIuMzc0MjEgOS4zMjcxMkMyLjMwMjM5IDkuNzA0MjUgMi42OTgzIDkuOTk3MjcgMy4wMzc5OSA5LjgxODQxTDUuMjMzMTMgOC42NjI1N0M1LjQwMDIxIDguNTc0NiA1LjU5OTkxIDguNTc0NiA1Ljc2Njk4IDguNjYyNTdMNy45NjIxMyA5LjgxODQxQzguMzAxODIgOS45OTcyNyA4LjY5NzcyIDkuNzA0MjUgOC42MjU5MSA5LjMyNzEyTDguMTcxNjIgNi45NDE0M0M4LjEzMjU3IDYuNzM2MzYgOC4yMDgxNiA2LjUyNjI3IDguMzY4OTIgNi4zOTMwOUwxMC4xMDU2IDQuOTU0MjhDMTAuNDEwNSA0LjcwMTY1IDEwLjI3MzIgNC4yMDcyOCA5Ljg4MTYzIDQuMTQ4MTRMNy41NTA0IDMuNzk2MDRDNy4zNzg1IDMuNzcwMDggNy4yMjc2OCAzLjY2NzQ2IDcuMTQwNDMgMy41MTcwOUw1Ljg5NjQ5IDEuMzczMzJaIiBmaWxsPSIjOTc5Nzk3Ii8+Cjwvc3ZnPgo=")
      margin-right: 2.5px
      margin-top: 1px
  &__time
    display: flex
    &::before
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjYwNDEzIDUuNTAwMDhDMS42MDQxMyAzLjM0ODQ3IDMuMzQ4MzUgMS42MDQyNSA1LjQ5OTk2IDEuNjA0MjVDNy42NTE1NyAxLjYwNDI1IDkuMzk1NzkgMy4zNDg0NyA5LjM5NTc5IDUuNTAwMDhDOS4zOTU3OSA3LjY1MTY5IDcuNjUxNTcgOS4zOTU5MSA1LjQ5OTk2IDkuMzk1OTFDMy4zNDgzNSA5LjM5NTkxIDEuNjA0MTMgNy42NTE2OSAxLjYwNDEzIDUuNTAwMDhaTTUuODQzNzEgMy4yMDg0MUM1Ljg0MzcxIDMuMDE4NTcgNS42ODk4MSAyLjg2NDY2IDUuNDk5OTYgMi44NjQ2NkM1LjMxMDExIDIuODY0NjYgNS4xNTYyMSAzLjAxODU3IDUuMTU2MjEgMy4yMDg0MVY1LjUwMDA4QzUuMTU2MjEgNS42MTg2IDUuMjE3MjcgNS43Mjg3NiA1LjMxNzc3IDUuNzkxNThMNi42OTI3NyA2LjY1MDk2QzYuODUzNzYgNi43NTE1NyA3LjA2NTg0IDYuNzAyNjMgNy4xNjY0NiA2LjU0MTY0QzcuMjY3MDggNi4zODA2NSA3LjIxODE0IDYuMTY4NTggNy4wNTcxNSA2LjA2Nzk2TDUuODQzNzEgNS4zMDk1NlYzLjIwODQxWiIgZmlsbD0iIzk3OTc5NyIvPgo8L3N2Zz4K")
      margin-right: 2.5px
      margin-top: 1px
  &.disabled
    opacity: 0.3
    cursor: not-allowed
  &.completed
    -webkit-filter: grayscale(100%)
    -moz-filter: grayscale(100%)
    -ms-filter: grayscale(100%)
    -o-filter: grayscale(100%)
    filter: grayscale(100%)
  
</style>