<template>
  <div class="finalslide">
    <button class="finalslide__close" @click="$router.push('/main')">
      <CloseIcon />
    </button>
    <div class="finalslide__title">Your result</div>
    <div class="finalslide__img" :class="{ 'finalslide__img_altspace': altspace }">
      <img :src="img" alt="">
    </div>
    <div class="finalslide__content">
      <div>
        <div class="finalslide__text" v-if="text" v-html="text">
        </div>
        <div class="finalslide__text" v-else>
          <slot />
        </div>
        <div class="finalslide__indicators-title">Your indicators</div>
        <div class="finalslide__indicators-subtitle">These indicators are based only on the situation that you’ve completed.</div>
        <div class="finalslide__indicators-flex">
          <div class="finalslide__tag finalslide__tag_green" v-for="t in indicators[0]" :key="t">{{ t }}</div>
          <div class="finalslide__tag finalslide__tag_red" v-for="t in indicators[1]" :key="t">{{ t }}</div>
        </div>
        <div class="finalslide__rating">
          <div class="finalslide__rating-score">{{ score }}</div>
          <div class="finalslide__rating-text">Rate the game</div>
          <RatingInput class="finalslide__rating-input" @setScore="$emit('setScore', $event)" />
        </div>
        <div class="finalslide__reccomend">
          <div class="finalslide__reccomend-title">We recommend</div>
          <div class="finalslide__swiper">
            <TileSwiper />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../common/CloseIcon.vue';
import RatingInput from '../common/RatingInput.vue';
import TileSwiper from '../TileSwiper.vue';

export default {
  props: {
    score: { default: '4,7' },
    img: { type: String, required: true },
    altspace: { type: Boolean, default: false },
    indicators: { type: Array },
    text: { type: String, default: '' }
  },
  components: { RatingInput, CloseIcon, TileSwiper }
}
</script>

<style lang="sass">
.finalslide
  font-family: fonts.$font1
  min-height: 100vh
  display: flex
  flex-direction: column
  &__close, &__title
    position: relative
    z-index: 1

  &__close
    align-self: flex-end
    margin: 68px 24px 4px 0
  &__title
    font-weight: 600
    font-size: 28px
    line-height: 34px
    margin-bottom: 34px
    margin-left: 24px
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    background: white
    border-radius: 30px 30px 0 0
    flex-grow: 1
    padding: 36px 24px 41px
    font-size: 18px
    line-height: 22px
    p:not(:first-child)
      margin-top: 1em
  &__img
    height: 433px
    flex-shrink: 0
    width: 100%
    margin: -100px 0 -68px
    pointer-events: none
    img
      display: block
      width: 100%
      height: 100%
      object-fit: scale-down
      object-position: center
    &_altspace
      display: flex
      justify-content: center
      img
        width: 473px
        height: 459px
        margin-top: -24px
  &__text
    font-size: 16px
    line-height: 19px
    margin-bottom: 36px
    p:not(:first-child)
      margin-top: 1.15em
  &__indicators
    &-title
      font-weight: 600
      font-size: 28px
      line-height: 34px
      margin-bottom: 8px
    &-subtitle
      font-size: 16px
      line-height: 19px
      color: colors.$grayfont
      margin-bottom: 12px
    &-flex
      display: flex
      margin-left: -10px
      width: calc(100% + 10px)
      margin-bottom: 44px
      flex-wrap: wrap
      >*
        margin-left: 10px
        margin-bottom: 10px
  &__tag
    border-radius: 10px
    padding: 10px 14px
    font-weight: 500
    font-size: 16px
    line-height: 19px
    &_green
      background: colors.$lightgreen
      color: colors.$green 
    &_red
      background: colors.$lightred
      color: colors.$red 
  &__rating
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    margin-bottom: 54px
    &-score
      font-weight: 500
      font-size: 32px
      line-height: 39px
      margin-bottom: 3px
    &-text
      font-size: 16px
      line-height: 19px
      margin-bottom: 32px
  &__reccomend
    margin-bottom: 60px
    &-title
      font-weight: 500
      font-size: 16px
      line-height: 19px
      margin-bottom: 24px
</style>