<template>
  <div style="padding: 20px; display: flex; flex-direction: column; gap: 10px; color: blue; font-weight: 600; font-size: 20px">
    <div><router-link to="/main">Главная</router-link></div>
    <div><router-link to='/statistics'>Статистика</router-link></div>
    <div><router-link to='/onboarding'>Онбординг</router-link></div>
    <div><router-link to='/login'>Вход</router-link></div>
    <div><router-link to='/register'>Регистрация</router-link></div>
    <div><router-link to='/profile'>Профиль</router-link></div>=
    <div><router-link to='/test/0'>Динамический тест</router-link></div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="sass" scoped>

</style>