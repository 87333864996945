<template>
  <label class="slidequestion" :class="{'active':active, 'locked':locked}">
    <div class="slidequestion__input">
      <input class="slidequestion__radio" type="radio" :name="name" :value="variant.value" v-model="mv" :disabled="locked">
      <div class="slidequestion__dot"></div>
    </div>
    <div class="slidequestion__text" v-html="variant.text" />
  </label>
</template>

<script>
  export default {
    props: {
      locked: { type: Boolean, default: false },
      variant: { type: Object, required: true },
      name: { type: String, required: true },
      modelValue: { type: String, required: true },
    },
    computed: {
      mv:{
        get(){
          return this.modelValue
        },
        set(v){
          this.$emit('update:modelValue',v)
        }
      },
      active(){
        return this.variant.value == this.modelValue
      }
    }
  }
</script>

<style lang="sass" scoped>
.slidequestion
  display: flex
  align-items: flex-start
  background: white
  transition: background .3s ease-out
  border-radius: 20px
  overflow: hidden
  cursor: pointer
  padding: 12px 15px 14px 20px
  font-weight: 500
  font-size: 16px
  line-height: 19px
  &.active
    background: colors.$lightviol
  &.locked.active
    background: #E0E0E0
  &__input
    padding-top: 3px
    position: relative
    margin-right: 16px
  &__radio
    position: absolute
    opacity: 0
    pointer-events: none
  &__radio:checked+&__dot
    &::after
      content: ''
      width: 14px
      height: 14px
      background: colors.$viol
      border-radius: 14px
  &.locked.active &__radio:checked+&__dot::after
    background: #828282
  &__dot
    display: flex
    justify-content: center
    align-items: center
    width: 26px
    height: 26px
    border-radius: 26px
    background: colors.$E7
  &+&
    margin-top: 19.5px


</style>