<template>
  <div class="startslide">
    <button class="startslide__close" @click="$router.push('/main')">
      <CloseIcon />
    </button>
    <div class="startslide__img">
      <img :src="img">
    </div>
    <div class="startslide__body">
      <div class="startslide__title">
          <span>{{ title }}</span>
<!--          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21.6395 3.99996C17.8709 3.99996 14.7405 3.99992 10.6239 3.99996C9.29412 4.22289 8.23511 5.24378 7.96847 6.56748C6.74698 12.6316 6.68033 18.8717 7.77203 24.9605L8.21649 27.4395C8.35354 28.2039 9.28542 28.5094 9.84843 27.9746L15.2134 22.8779C15.728 22.389 16.5354 22.389 17.0501 22.8779L22.415 27.9746C22.978 28.5094 23.9099 28.2039 24.0469 27.4395L24.4914 24.9605C25.5831 18.8717 25.5165 12.6316 24.295 6.56748C24.0283 5.24378 22.9693 4.22289 21.6395 3.99996Z" fill="#E7EAEE"/> </svg>-->
      </div>
      <p class="startslide__text" v-html="text"></p>
      <div class="startslide__stats">
        <div class="startslide__stat">
          <div class="startslide__stat-name">Time</div>
          <div class="startslide__stat-value">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M2.3335 8C2.3335 4.87038 4.87055 2.33333 8.00016 2.33333C11.1298 2.33333 13.6668 4.87038 13.6668 8C13.6668 11.1296 11.1298 13.6667 8.00016 13.6667C4.87055 13.6667 2.3335 11.1296 2.3335 8ZM8.50016 4.66666C8.50016 4.39052 8.27631 4.16666 8.00016 4.16666C7.72402 4.16666 7.50016 4.39052 7.50016 4.66666V8C7.50016 8.17239 7.58897 8.33263 7.73516 8.42399L9.73516 9.67399C9.96933 9.82035 10.2778 9.74916 10.4242 9.51499C10.5705 9.28083 10.4993 8.97235 10.2652 8.826L8.50016 7.72287V4.66666Z" fill="#6A6A6A"/> </svg>
            {{ time }}
          </div>
        </div>
        <div class="startslide__divideline"></div>
        <div class="startslide__stat">
          <div class="startslide__stat-name">Rating</div>
          <div class="startslide__stat-value">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.71632 2.03027C8.45521 1.58028 7.80538 1.58028 7.54427 2.03027L5.7054 5.19932C5.57642 5.4216 5.35348 5.5733 5.09936 5.61168L1.6532 6.13218C1.07439 6.2196 0.871358 6.9504 1.32213 7.32386L3.88937 9.45079C4.12701 9.64768 4.23875 9.95824 4.18103 10.2614L3.50947 13.7881C3.40331 14.3456 3.98856 14.7787 4.49072 14.5143L7.73571 12.8057C7.9827 12.6756 8.2779 12.6756 8.52488 12.8057L11.7699 14.5143C12.272 14.7787 12.8573 14.3456 12.7511 13.7881L12.0796 10.2614C12.0218 9.95824 12.1336 9.64768 12.3712 9.45079L14.9385 7.32386C15.3892 6.9504 15.1862 6.2196 14.6074 6.13218L11.1612 5.61168C10.9071 5.5733 10.6842 5.4216 10.5552 5.19932L8.71632 2.03027Z" fill="#6A6A6A"/> </svg>
            {{ rating }}
          </div>
        </div>
      </div>
      <div class="startslide__body-filler"></div>
      <ViolBtn :afterspace="true" @click="$parent.$emit('next')">Start</ViolBtn>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../common/CloseIcon.vue';
import ViolBtn from '../common/ViolBtn.vue';

export default {
  props: {
    img: { type: String, required: true },
    title: { type: String, required: true },
    text: { type: String, required: true },
    time: { type: String, default: "10 min" },
    rating: { type: String, default: "4,7" }
  },
  components: { ViolBtn, CloseIcon }
}
</script>

<style lang="sass" scoped>
.startslide
  background: colors.$F7
  position: relative
  display: flex
  flex-direction: column
  &__body
    flex-grow: 1
    padding: 0 24px
    display: flex
    flex-direction: column
    &-filler
      flex-grow: 100
  &__close
    position: absolute
    top: 68px
    right: 24px
  &__img
    border-radius: 0px 0px 30px 30px
    width: 100%
    height: 268px
    overflow: hidden
    margin-bottom: 16px
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__title
    display: flex
    align-items: flex-start
    justify-content: space-between
    font-weight: 600
    font-size: 28px
    line-height: 34px
    margin-bottom: 17px
    svg
      margin-left: 10px
  &__text
    font-size: 18px
    line-height: 22px
    color: colors.$g6a
    margin-bottom: 28px
  &__stats
    display: flex
    justify-content: center
    margin-right: 20px
    margin-bottom: 32px
  &__stat
    padding: 13px 0 9px
    text-align: center
    font-size: 18px
    line-height: 22px
    color: colors.$g6a
    &-name
      margin-top: 2px
      margin-bottom: 4px
    &-value
      font-weight: 500
      display: flex
      align-items: center
      svg
        margin-right: 4px
  &__divideline
    display: block
    width: 1px
    background: #BFBFBF
    margin: 0 32px
  @media(max-height: 650px)
    &__img
      height: 200px
    &__close
      top: 50px
</style>