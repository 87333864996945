<template>
  <div class="progressbar">
    <div class="progressbar__filler" :style="`width: ${progress}%`"></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: { type: Number, required: true }
  }
}
</script>

<style lang="sass" scoped>
.progressbar
  border-radius: 30px
  background: white
  overflow: hidden
  height: 12px
  &__filler
    transition: width .3s ease-out
    height: 100%
    background: colors.$viol
</style>