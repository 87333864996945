import { createStore } from 'vuex'

export default createStore({
  state: {
    serverUrl: '',
    mockEnabled: false,
    fetchReplaced: false,
    profile: {
      name: 'Helen Helen',
      date: 'Joined April 2022',
      photo: require(`@/assets/img/profile/helen.jpg`),
      mode: 0
    }
  },
  mutations: {
    setMode(state,mode) {
      state.mode = mode
    },
    setFetchReplaced(state, v) {
      state.fetchReplaced = v
    },
    setServerUrl(state, url) {
      state.serverUrl = url
    }
  },
  actions: {
    updateProfile({state}, obj) {
      for (let key in obj) {
        state.profile[key] = obj[key]
      }
    }
  },
  modules: {
  }
})
