<template>
  <div class="slide">
    <div class="slide__img">
      <img :src="img">
    </div>
    <div class="slide__title">{{ title }}</div>
    <div class="slide__subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      title: { type: String },
      subtitle: { type: String },
      img: { type: String }
    }
  }
</script>

<style lang="sass" scoped>
.slide
  text-align: center
  display: flex
  flex-direction: column
  padding: 9.6vh 30px 4.7vh
  @media (max-height: 600px)
    padding: 50px 30px 4.7vh
  &__img
    flex-grow: 1
    position: relative
    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: scale-down
      object-position: top center
  &__title
    font-weight: 500
    font-size: 32px
    line-height: 39px
    margin-bottom: 12px
  &__subtitle
    font-size: 18px
    line-height: 22px
    color: colors.$grayfont

</style>