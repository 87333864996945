<template>
  <div class="descriptionslide" >
    <SlideHead :progress="progress" @back="$parent.$emit('back')">
      <div class="descriptionslide__title">Description</div>
    </SlideHead>      
    <div class="descriptionslide__content">
      <div v-if="text" v-html="text">
      </div>
      <div v-else>
        <slot />
      </div>
      <ViolBtn class="descriptionslide__btn" @click="$parent.$emit('next')">Continue</ViolBtn>
    </div>
  </div>
</template>

<script>
import SlideHead from '../common/slideHead.vue';
import ViolBtn from '../common/ViolBtn.vue';

export default {
  props: {
    progress: { type: Number, required: true },
    text: { type: String, default: '' }
  },
  components: { ViolBtn, SlideHead }
}
</script>

<style lang="sass">
.descriptionslide
  font-family: fonts.$font1
  background-size: 100% auto !important
  background-repeat: repeat-y !important
  min-height: 100vh
  display: flex
  flex-direction: column

  &__title
    font-weight: 600
    font-size: 28px
    line-height: 34px
    margin-bottom: 34px
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    background: white
    border-radius: 30px 30px 0 0
    flex-grow: 1
    padding: 36px 24px 41px
    font-size: 18px
    line-height: 22px
    p:not(:first-child)
      margin-top: 1.2em
  &__btn
    margin-top: 24px
</style>