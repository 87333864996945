<template>
  <div class="register">
    <div class="register__slides">
      <div class="register__slides-flex" :style="`transform: translateX(-${(step - 1) * 200}%)`">
        <div class="fieldslide">
          <div class="fieldslide__img">
            <img :src="require('@/assets/img/register/newacc.svg')">
          </div>
          <div class="fieldslide__title fieldslide__title_1">
            <div class="fieldslide__title-big">Create </div>
            New Account
          </div>
          <InputField v-model="slides[0].name" :required="true" ref="name" placeholder="Name" />
          <InputField v-model="slides[0].email" :required="true" ref="email" placeholder="E-mail" />
        </div>
        <div class="fieldslide">
          <div class="fieldslide__img fieldslide__img_staying">
            <img :src="require('@/assets/img/register/password.svg')">
          </div>
          <div class="fieldslide__title fieldslide__title_2">
            Create password
          </div>
          <InputField v-model="slides[1].password" :required="true" ref="password" type="password" placeholder="Password" style="margin-bottom: 10px"/>
        </div>
      </div>
    </div>
    <div class="register__constant">
      <div class="register__btnwrap">
        <ViolBtn @click="toStep" :required="true">Continue</ViolBtn>
      </div>
<!--      <div class="register__socials" :class="{'hidden': step > 1}">
        <Social type="facebook"/>
        <Social type="google"/>
        <Social type="twitter"/>
      </div>-->
      <div class="register__text" :class="{'hidden': step > 1}">
        Already have an account? <br> 
        <a class="register__text-viol login__link" @click="$router.push('/login')">Sign in</a>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '../components/common/InputField.vue';
// import Social from '../components/common/Social.vue';
import ViolBtn from '../components/common/ViolBtn.vue';


export default {
    data: () => ({
        step: 1,
        stepcount: 2,
        slides: [{
          name: '',
          email: ''
        },{
          password: ''
        }
      ]
    }),
    async beforeCreate() {
      let result = await fetch(this.$store.state.serverUrl + '/register', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        if (!res.ok) throw ('register: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      if (result.status != 'ok') {
        if (result.redirect_url) {
          this.$router.push(result.redirect_url)
          return
        }

        alert(result.message)
        return null
      }
    },
    components: { InputField, ViolBtn/*, Social*/ },
    methods: {
      async toStep(){
        let step = this.step - 1
        let ok = true
        for (let key in this.slides[step])
        ok *= this.$refs[key].isCorrect()

        if (this.step == this.stepcount) {
          let result = await this.register()

          if (result.status == 'ok') {
            if (result.redirect_url)
              this.$router.push(result.redirect_url)
            else
              this.$router.push('/profile')
          }
          else {
            alert(result.message)
          }

          return
        }

        if (ok) this.step++
      },
      async register() {
        let formData = {};
        for (let item of this.slides) {
          for (let key in item) {
            formData[key] = item[key]
          }
        }
        console.log('register_form_data:')
        console.log(formData)

        return await fetch(this.$store.state.serverUrl + '/auth/register', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }).then(res => {
          if (!res.ok) throw ('register: not_ok')
          return res.json()
        }).catch(err => {
          console.error(err)
          return null
        })
      }
    }
}
</script>

<style lang="sass">
.fieldslide
  text-align: center
  display: flex
  flex-direction: column
  padding: 9.6vh 15px 31px
  &__img
    flex-grow: 1
    position: relative
    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: scale-down
      object-position: top center
  &__title
    position: relative
    z-index: 1
    font-weight: 500
    font-size: 24px
    line-height: 29px
    margin-bottom: 32px
    &-big
      font-size: 32px
      line-height: 39px
    &_1
      margin-top: -70px
    &_2
      margin: 0 auto 40px
  &__subtitle
    font-size: 18px
    line-height: 22px
    color: colors.$grayfont
  @media (max-height: 700px)
    padding: 50px 15px 16px
    &__title
      margin-bottom: 16px
      &_1
        margin-top: -50px
  @media (max-height: 550px)
    justify-content: center
    &__title
      margin-top: 0
    &__img
      &:not(&_staying)
        display: none
.register, .login
  font-family: fonts.$font1
  height: 100vh
  min-height: 460px
  overflow: hidden
  display: flex
  flex-direction: column
  color: colors.$mainfont
  background: colors.$F7
  &__slides
    flex-basis: 568px
    flex-shrink: 1
    flex-grow: 2
    width: 100%
    overflow: hidden
    @media (max-height: 550px)
      flex-basis: 270px
    &-flex
      height: 100%
      width: 100%
      display: flex
      transition: transform .5s ease
      >*
        width: 100%
        min-width: 100%
        margin-right: 100%
  &__constant
    padding: 0 15px
    flex-basis: 246px
    flex-grow: 1
    flex-shrink: 5
    display: flex
    flex-direction: column
  &__socials
    flex-shrink: 1
    flex-grow: 1
    display: flex
    min-height: 45px
    align-items: flex-start
    justify-content: center
    >*:not(:last-child)
      margin-right: 10px
  &__btnwrap
    flex-grow: 0.9
    min-height: 70px
    display: flex
    align-items: flex-start
    flex-shrink: 1
    justify-content: center
  &__text
    text-align: center
    flex-grow: 1.35
    min-height: 70px
    flex-shrink: 1
    font-size: 18px
    line-height: 22px
    color: colors.$grayfont
    &-viol
      display: block
      margin-top: 4px
      color: colors.$viol
      font-weight: 500
  &__text, &__socials
    transition: opacity .4s ease-out
    &.hidden
      opacity: 0
      pointer-events: none
.login__link
  cursor: pointer
</style>