<template>
  <div class="profileinfo">
    <div class="profileinfo__img">
      <img :src="img">
    </div>
    <div class="profileinfo__body">
      <div class="profileinfo__textdata">
        <div class="profileinfo__name">{{ name }}</div>
        <div class="profileinfo__date">{{ date }}</div>
      </div>
      <div class="profileinfo__btns" v-if="!nobtns">
        <button @click="$emit('edit')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.0001 20.0001H5.00005C4.73484 20.0001 4.48048 20.1055 4.29295 20.293C4.10541 20.4805 4.00005 20.7349 4.00005 21.0001C4.00005 21.2653 4.10541 21.5197 4.29295 21.7072C4.48048 21.8947 4.73484 22.0001 5.00005 22.0001H19.0001C19.2653 22.0001 19.5196 21.8947 19.7072 21.7072C19.8947 21.5197 20.0001 21.2653 20.0001 21.0001C20.0001 20.7349 19.8947 20.4805 19.7072 20.293C19.5196 20.1055 19.2653 20.0001 19.0001 20.0001ZM5.00005 18.0001H5.09005L9.26005 17.6201C9.71685 17.5746 10.1441 17.3733 10.4701 17.0501L19.4701 8.0501C19.8194 7.68107 20.0082 7.18861 19.995 6.68064C19.9819 6.17267 19.768 5.69061 19.4001 5.3401L16.6601 2.6001C16.3024 2.2642 15.8338 2.07146 15.3434 2.05855C14.8529 2.04565 14.3748 2.21347 14.0001 2.5301L5.00005 11.5301C4.67682 11.8561 4.47556 12.2833 4.43005 12.7401L4.00005 16.9101C3.98658 17.0566 4.00559 17.2042 4.05571 17.3425C4.10584 17.4808 4.18585 17.6063 4.29005 17.7101C4.38349 17.8028 4.49431 17.8761 4.61615 17.9259C4.73798 17.9756 4.86845 18.0009 5.00005 18.0001ZM15.2701 4.0001L18.0001 6.7301L16.0001 8.6801L13.3201 6.0001L15.2701 4.0001Z" fill="#979797"/> </svg>
        </button>
<!--        <button @click="$emit('share', info)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.55394 16.0608C2.4578 16.2507 2.49237 16.4806 2.6401 16.6338C2.78782 16.787 3.01632 16.8299 3.20954 16.7407L5.70888 15.5872C7.80948 14.6177 10.1018 14.1741 12.3902 14.2785C12.4174 15.0133 12.4577 15.7478 12.5112 16.4816L12.5797 17.4205C12.6212 17.9902 13.2564 18.3086 13.7376 18.0011C15.8266 16.6663 17.6427 14.9463 19.089 12.9329L19.5492 12.2923C19.6744 12.118 19.6744 11.8832 19.5492 11.7089L19.089 11.0683C17.6427 9.05486 15.8266 7.33491 13.7376 6.00009C13.2564 5.69255 12.6212 6.01099 12.5797 6.58064L12.5112 7.5196C12.4653 8.14945 12.4291 8.77984 12.4025 9.41053H11.7595C8.17848 9.41053 4.90176 11.4242 3.28406 14.6189L2.55394 16.0608Z" fill="#979797"/> </svg>
        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      nobtns: { type: Boolean, default: false },
      img: { type: String },
      name: { type: String },
      date: { type: String }
    },
    computed: {
      info(){
        return {
          img: this.img,
          name: this.name,
          date: this.date
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
.profileinfo
  display: flex
  font-family: fonts.$font1
  &__img
    width: 108px
    height: 108px
    border-radius: 9999px
    overflow: hidden
    position: relative
    margin-right: 20px
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__body
    display: flex
    flex-direction: column
  &__textdata
    flex-grow: 100
    margin-bottom: 8px
    display: flex
    flex-direction: column
    justify-content: flex-end
  &__name
    font-size: 24px
    line-height: 29px
    margin-bottom: 2px
  &__date
    font-size: 14px
    line-height: 17px
    color: colors.$grayfont
  &__btns
    flex-grow: 1
    display: flex
    align-items: flex-start
    >*:not(:last-child)
      margin-right: 12px

    
</style>