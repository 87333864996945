<template>
  <div class="register">
    <div class="register__slides">
      <div class="register__slides-flex" :style="`transform: translateX(-0%)`">
        <div class="fieldslide">
          <div class="fieldslide__img">

          </div>
          <div class="fieldslide__title fieldslide__title_3 fieldslide__title-big">
            Password
          </div>
          <InputField v-model="currentPassword" :required="true" ref="currentPassword" type="password" placeholder="Current password"/>
          <InputField v-model="newPassword" :required="true" ref="newPassword" type="password" placeholder="New password"/>
          <InputField v-model="newPasswordConfirm" :required="true" ref="newPasswordConfirm" type="password" placeholder="New password confirm"/>
        </div>
      </div>
    </div>
    <div class="register__constant">
      <div class="register__btnwrap">
        <ViolBtn @click="save">Save</ViolBtn>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '../components/common/InputField.vue';
import ViolBtn from '../components/common/ViolBtn.vue';

export default {
  data: ()=>({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  }),

  methods: {
    async save(){
      let result = await fetch(this.$store.state.serverUrl + '/profile/change-password', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({password: this.currentPassword, new_password: this.newPassword, new_password_confirm: this.newPasswordConfirm})
      }).then(res => {
        if (!res.ok) throw ('change-user-password: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      if (result.status == 'ok') {
        alert('Пароль успешно изменен')
        this.$router.push('/profile')
      }
      else {
        alert(result.message)
      }
    },
    async getUserData() {
      return await fetch(this.$store.state.serverUrl + '/profile/get-data', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(formData)
      }).then(res => {
        if (!res.ok) throw ('get-user-data: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })
    },
    async logout() {
      let result = await fetch(this.$store.state.serverUrl + '/auth/logout', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(formData)
      }).then(res => {
        if (!res.ok) throw ('logout: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      console.log('result_data:')
      console.log(result)

      if (result.status == 'ok') {
        if (result.redirect_url != '')
          this.$router.push(result.redirect_url)
        else
          this.$router.push('/login')
      }
      else {
        alert(result.message)
      }
    }
  },
  async created() {
    let result = await this.getUserData();
    console.log('result_data:')
    console.log(result)

    if (result.status == 'ok') {
      if (result.user_data) {
        let user_data = result.user_data;

        this.newname = user_data.name;

        this.$store.dispatch('updateProfile', {name: user_data.name, photo: user_data.photo, date: 'Joined ' + user_data.register_date})
        this.edit = false
      }
    }
    else {
      if (result.redirect_url !== undefined) {
        this.$router.push(result.redirect_url)

        return
      }

      alert(result.message)
    }
  },
  mounted(){
    this.$store.commit('setMode', 2)
  },
  components: { ViolBtn, InputField }
}
</script>

<style lang="sass">
.fieldslide
  &__title
    &_3
      margin-top: -45px
.register__link
  cursor: pointer
</style>