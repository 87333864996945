<template>
  <div class="resultslide">
    <SlideHead :progress="progress" @back="$parent.$emit('back')" />
    <div class="resultslide__content">
      <div>
        <div class="resultslide__img">
          <img :src="img">
        </div>
        <div class="resultslide__text" v-html="text"></div>
      </div>
      <ViolBtn class="resultslide__btn" @click="$parent.$emit('next')">Complete</ViolBtn>
    </div>
  </div>
</template>

<script>
import SlideHead from '../common/slideHead.vue';
import ViolBtn from '../common/ViolBtn.vue';

export default {
  components: { ViolBtn, SlideHead },
  props: {
    text: { type: String, required: true },
    img: { type: String, default: require('@/assets/img/tests/result.svg')},
    progress: { type: Number, required: true },
  },
}
</script>

<style lang="sass">
.resultslide
  font-family: fonts.$font1
  min-height: 100vh
  display: flex
  flex-direction: column
  box-sizing: content-box
  
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    flex-grow: 1
    padding: 1px 24px 41px
    font-size: 18px
    line-height: 22px
  &__img
    height: 221px
    width: 100%
    margin: 11px 0 23px
    img
      width: 100%
      height: 100%
      object-fit: contain
      object-position: center
  &__text
    padding: 36px 16px
    border-radius: 30px
    background: white
  &__btn
    margin-top: 24px
</style>