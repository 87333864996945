<template>
  <div class="statistics">
    <div class="statistics__content">
      <div class="statistics__grid">
        <div class="statistics__grid-tile statistics__grid-tile_viol">
          <img :src="require('@/assets/img/statistics/viol.svg')">
          <div class="statistics__grid-tile-title statistics__grid-tile-title_smspace">{{ completed_game_count }}</div>
          <div class="statistics__grid-tile-text">games completed</div>
        </div>
        <div class="statistics__grid-tile statistics__grid-tile_doublerow">
          <div class="statistics__grid-tile-title">{{ this.avg_response_sel_time_str }}</div>
          <div class="statistics__grid-tile-text">average response selection time</div>
          <template v-if="avg_response_sel_time > 30">
            <div class="statistics__grid-tile-line"></div>
            <div class="statistics__grid-tile-text">You probably think a lot before choosing an answer. This may indicate your insecurity.</div>
          </template>
        </div>
        <div class="statistics__grid-tile statistics__grid-tile_orange statistics__grid-tile_moreround">
          <img :src="require('@/assets/img/statistics/orange.png')">
          <div class="statistics__grid-tile-title">{{ register_period }}</div>
          <div class="statistics__grid-tile-text">you’ve used Deeper </div>
        </div>
      </div>
      <div class="statistics__title">Your indicators</div>
      <div class="statistics__subtitle">Keep it in the middle</div>
      <template v-if="show_indicators">
        <LineSlider :blurtrace="indicators.personal_boundaries.offset" title="Personal boundaries" min="Too flexible" max="Too tight" :value="indicators.personal_boundaries.value"/>
        <LineSlider :blurtrace="indicators.self_esteem.offset" title="Self-Esteem" min="Uncertainty" max="Narcissism" :value="indicators.self_esteem.value"/>
        <LineSlider :blurtrace="indicators.healthy_relationships.offset" title="Ability to build healthy relationships" min="Codependency" max="Independence" :value="indicators.healthy_relationships.value"/>
        <LineSlider :blurtrace="indicators.emotional_intellect.offset" title="Emotional intellect" min="Insensitive" max="Sensitive" :value="indicators.emotional_intellect.value"/>
        <LineSlider :blurtrace="indicators.contact_with_yourself.offset" title="Contact with yourself" min="Too low" max="Too high" :value="indicators.contact_with_yourself.value"/>
        <div class="statistics__subtitle statistics__subtitle_2">Keep it low</div>
        <LineSlider :blurtrace="indicators.anxiety.offset" title="Anxiety" min="Too low" max="Too high" :value="indicators.anxiety.value"/>
      </template>
      <div style="text-align: center" v-else>0</div>
    </div>
    <Panel />
  </div>
</template>

<script>
import LineSlider from '../components/LineSlider.vue';
import Panel from '../components/Panel.vue';

export default {
  components: { Panel, LineSlider },
  async created(){
    await this.getStatisticsData()
  },
  mounted(){
    this.$store.commit('setMode', 0)
  },
  data: ()=>({
    completed_game_count: 0,
    avg_response_sel_time: 0,
    avg_response_sel_time_str: '0 sec',
    register_period: '0 days',
    indicators: {
      personal_boundaries: 50,
      self_esteem: 50,
      healthy_relationships: 50,
      emotional_intellect: 50,
      contact_with_yourself: 50,
      anxiety: 50
    }
  }),
  methods: {
    async getStatisticsData() {
      let result = await fetch(this.$store.state.serverUrl + '/statistics/get-user-data', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        if (!res.ok) throw ('main: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      if (result.status == 'ok') {
        if (result.statistics_data) {
          let stats = result.statistics_data

          this.completed_game_count = stats.completed_game_count
          this.avg_response_sel_time = stats.avg_response_sel_time
          this.register_period = stats.register_period
          this.indicators = stats.indicators


          // Формируем строку со средним временем выбора ответа на вопрос в тестах
          this.avg_response_sel_time_str = ''

          let arr_avg_response_sel_time = []

          let time = new Date(stats.avg_response_sel_time * 1000)
          let h = time.getUTCHours()
          let m = time.getUTCMinutes()
          let s = time.getUTCSeconds()

          if (h > 0) {
            arr_avg_response_sel_time.push(h + ' h');
          }
          if (m > 0) {
            arr_avg_response_sel_time.push(m + ' min');
          }
          if ((arr_avg_response_sel_time.length > 0 && s > 0) || arr_avg_response_sel_time.length == 0) {
            arr_avg_response_sel_time.push(s + ' sec');
          }

          this.avg_response_sel_time_str = arr_avg_response_sel_time.join(' ');
        }
      }
      else {
        alert(result.message)
      }
    }
  },
  computed: {
    show_indicators: {
      get(){
        return this.completed_game_count > 1
      }
    }
  }
}
</script>

<style lang="sass">
.statistics
  font-family: fonts.$font1
  min-height: 100vh
  color: colors.$mainfont
  background: linear-gradient(to top, #e5ccfd 0%, #f8f3fd 100%)
  display: flex
  flex-direction: column
  &__content
    flex-grow: 1
    padding: 68px 15px 65px
  &__grid
    display: grid
    gap: 20px 9px
    grid-gap: 20px 9px
    margin-bottom: 32px
    grid-template-columns: 1fr 1fr
    &-tile
      background-color: white
      border-radius: 15px
      overflow: hidden 
      padding: 9px 15px
      &_moreround
        border-radius: 30px
      &_doublerow
        grid-row: span 2
      &_viol
        padding-right: 40px
        padding-top: 14px
        padding-bottom: 16px
        position: relative
        z-index: 1
        img
          z-index: -1
          position: absolute
          top: 0
          right: 0
          height: 119px
      &_orange
        position: relative
        z-index: 1
        padding-bottom: 16px
        padding-right: 40px
        img
          z-index: -1
          position: absolute
          bottom: 0
          right: 0
          height: 82.25px

      &-title
        font-family: fonts.$font4
        font-weight: 400
        font-size: 36px
        line-height: 47px
        margin-bottom: 3px
        &_smspace
          margin-bottom: -2px
      &-line
        width: 100%
        height: 12px
        margin: 16.5px 0
        background: linear-gradient(90deg, #EBF1FF 1.45%, #A37AFA 100%)
        border-radius: 30px
      &-text
        font-size: 14px
        line-height: 17px
        color: colors.$grayfont
  &__title
    font-size: 24px
    line-height: 31px
    margin-bottom: 4px
    font-family: fonts.$font4
    text-align: center
  &__subtitle
    color: colors.$grayfont
    text-align: center
    font-weight: 400
    font-size: 14px
    line-height: 17px
    margin-bottom: 24px
    &_2
      margin: 24px auto 16px

</style>