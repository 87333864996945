<template>
  <div class="profile">
    <div class="profile__content">
      <div class="profile__window" :style="`transform: translateX(-${200 * edit}%)`">
        <div class="profile__main" :class="{'active': !edit}">
          <ProfileInfo class="profile__profileinfo" :img="profile.photo" :name="profile.name" :date="profile.date" @share="shareShowed = true" @edit="edit=true"/>
          <div class="profile__swiper">
            <TileSwiper />
          </div>
<!--          <a class="profile__link" @click="$router.push('/statistics')">Notification</a>
          <a class="profile__link" @click="$router.push('/profile')">Account</a>
          <a class="profile__link" @click="openPage('/privacy')">Privacy</a>-->
          <a class="profile__link" @click="$router.push('/change-password')">Password</a>
          <button @click="logout" class="profile__logout">Log Out</button>
        </div>
        <div class="profile__edit" :class="{'active': edit}">
          <div class="profile__edit-body">
            <Backlink :afterspace="true" @click="edit=false"/>
            <ProfileInfo class="profile__profileinfo-2" :img="profile.photo" :name="profile.name" :date="profile.date" @share="shareShowed = true" :nobtns="true"/>
            <div class="profile__edit-input">
              Name
              <InputField v-model="newname"/>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.0001 20.0001H5.00005C4.73484 20.0001 4.48048 20.1055 4.29295 20.293C4.10541 20.4805 4.00005 20.7349 4.00005 21.0001C4.00005 21.2653 4.10541 21.5197 4.29295 21.7072C4.48048 21.8947 4.73484 22.0001 5.00005 22.0001H19.0001C19.2653 22.0001 19.5196 21.8947 19.7072 21.7072C19.8947 21.5197 20.0001 21.2653 20.0001 21.0001C20.0001 20.7349 19.8947 20.4805 19.7072 20.293C19.5196 20.1055 19.2653 20.0001 19.0001 20.0001ZM5.00005 18.0001H5.09005L9.26005 17.6201C9.71685 17.5746 10.1441 17.3733 10.4701 17.0501L19.4701 8.0501C19.8194 7.68107 20.0082 7.18861 19.995 6.68064C19.9819 6.17267 19.768 5.69061 19.4001 5.3401L16.6601 2.6001C16.3024 2.2642 15.8338 2.07146 15.3434 2.05855C14.8529 2.04565 14.3748 2.21347 14.0001 2.5301L5.00005 11.5301C4.67682 11.8561 4.47556 12.2833 4.43005 12.7401L4.00005 16.9101C3.98658 17.0566 4.00559 17.2042 4.05571 17.3425C4.10584 17.4808 4.18585 17.6063 4.29005 17.7101C4.38349 17.8028 4.49431 17.8761 4.61615 17.9259C4.73798 17.9756 4.86845 18.0009 5.00005 18.0001ZM15.2701 4.0001L18.0001 6.7301L16.0001 8.6801L13.3201 6.0001L15.2701 4.0001Z" fill="#979797"/> </svg>
            </div>
            <div class="profile__edit-input">
              Photo
              <InputFieldFile v-model="newphoto" ref="photo" />
            </div>
            <div class="profile__edit-date">{{ profile.date }}</div>
          </div>
          <ViolBtn @click="save">Save</ViolBtn>
        </div>
      </div>
    </div>
    <Panel :hidden="edit"/>
  </div>
<!--  <SharePanel v-model="shareShowed"/>-->
</template>

<script>
import Backlink from '../components/common/Backlink.vue';
import InputField from '../components/common/InputField.vue';
import ViolBtn from '../components/common/ViolBtn.vue';
import Panel from '../components/Panel.vue';
import TileSwiper from '../components/TileSwiper.vue';
import ProfileInfo from '../components/ProfileInfo.vue';
// import SharePanel from '../components/SharePanel.vue';

import { mapState } from 'vuex'
import InputFieldFile from "@/components/common/InputFieldFile";
export default {
  data: ()=>({
    edit: false,
    newname: 'Helen Helen',
    newphoto: '',
    panelHidden: false,
    shareShowed: false
  }),

  methods: {
    async save(){
      let formData = new FormData()
      formData.append('name', this.newname)
      formData.append('photo', this.newphoto)

      let result = await fetch(this.$store.state.serverUrl + '/profile/update-data', {
        method: 'POST',
        body: formData
      }).then(res => {
        if (!res.ok) throw ('update-user-data: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      if (result.status == 'ok') {
        this.setUserData(result.user_data)
      }
      else {
        alert(result.message)
      }
    },
    async getUserData() {
      return await fetch(this.$store.state.serverUrl + '/profile/get-data', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        if (!res.ok) throw ('get-user-data: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })
    },
    async logout() {
      let result = await fetch(this.$store.state.serverUrl + '/auth/logout', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(formData)
      }).then(res => {
        if (!res.ok) throw ('logout: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      console.log('result_data:')
      console.log(result)

      if (result.status == 'ok') {
        if (result.redirect_url != '')
          this.$router.push(result.redirect_url)
        else
          this.$router.push('/login')
      }
      else {
        alert(result.message)
      }
    },
    openPage(route) {
      const routeData = this.$router.resolve({path: route});
      window.open(routeData.href, '_blank');
    },
    setUserData(user_data) {
      if (!user_data) return

      this.newname = user_data.name;

      this.$store.dispatch('updateProfile', {name: user_data.name, photo: user_data.photo, date: 'Joined ' + user_data.register_date})
      this.edit = false
    }
  },
  computed: {
    ...mapState(['profile'])
  },
  async created() {
    let result = await this.getUserData();
    console.log('result_data:')
    console.log(result)

    if (result.status == 'ok') {
      this.setUserData(result.user_data)
    }
    else {
      if (result.redirect_url !== undefined) {
        this.$router.push(result.redirect_url)

        return
      }

      alert(result.message)
    }
  },
  mounted(){
    this.$store.commit('setMode', 2)
  },
  components: {InputFieldFile, Panel, ViolBtn, ProfileInfo, /*SharePanel,*/ InputField, Backlink, TileSwiper }
}
</script>

<style lang="sass" scoped>
.profile
  min-height: 100vh
  background: colors.$F7
  font-family: fonts.$font1
  display: flex
  flex-direction: column
  &__profileinfo
    margin-bottom: 39px
  &__profileinfo-2
    margin-bottom: 25px
  &__content
    display: flex
    overflow: hidden
    width: 100%
    flex-grow: 1
  &__window
    display: flex
    transition: transform .5s ease
    width: 100%
    max-width: 100%
    overflow: visible
    >*
      padding: 59px 15px 0
      width: 100%
      min-width: 100%
      margin-right: 100%
      &:not(.active)
        max-height: 100vh
        overflow: hidden
  &__swiper
    margin-bottom: 53px
  &__link
    display: block
    font-size: 16px
    line-height: 19px
    color: colors.$grayfont
    padding-bottom: 12px
    border-bottom: 1px solid #D9D9D9
    cursor: pointer
    &+&
      margin-top: 11px
  &__logout
    font-weight: 500
    font-size: 16px
    line-height: 19px
    margin: 39px 0 20px
  &__main
    @media (min-height: 600px)
      padding-top: 12px
  &__edit
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-bottom: 48px
    &-input
      position: relative
      margin-bottom: 10px
      input
        padding-right: 45px
      svg
        position: absolute
        top: 50%
        right: 12px
        transform: translateY(-50%)
    &-date
      font-size: 14px
      line-height: 17px
      color: colors.$grayfont
      margin-bottom: 32px
    
</style>