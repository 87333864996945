import { createRouter, createWebHashHistory } from 'vue-router'
import Demo from '../views/Demo.vue'
import Main from '../views/Main.vue'
import Statistics from '../views/Statistics.vue'
import Onboarding from '../views/Onboarding.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import DynamicTest from '../views/tests/DynamicTest.vue'
import ChangePassword from "@/views/ChangePassword";
import Privacy from "@/views/Privacy";

const routes = [
  {
    path: '/demo',
    name: 'Demo',
    component: Demo
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/test/:id',
    name: 'Test',
    component: DynamicTest
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  /*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  }*/
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
