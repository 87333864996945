<template>
  <div class="lineslider">
    <div class="lineslider__title">{{ title }}</div>
    <div class="lineslider__boundaries">
      <div>{{ min }}</div>
      <div>{{ max }}</div>
    </div>
    <div class="lineslider__value">
      <div v-if="blurtrace" class="lineslider__blurtrace" :style="`${blurtrace>0? `left:calc(${value}% - 12px)` : `right: calc(${100 - value}% - 12px)`};width:calc(${Math.abs(blurtrace)}% + 24px)`"></div>
      <div class="lineslider__circle" :style="`left:${value}%`"></div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title: { type: String },
    min: { type: String },
    max: { type: String },
    value: { type: Number },
    blurtrace: { type: Number, default: 0 },
  }
}
</script>

<style lang="sass" scoped>
.lineslider
  border-radius: 30px
  background: white
  padding: 8px 15px 18px
  &+&
    margin-top: 16px
  &__title
    text-align: center
    font-weight: 500
    font-size: 14px
    line-height: 17px
    margin-bottom: 7px
  &__boundaries
    display: flex
    color: colors.$grayfont
    font-weight: 500
    font-size: 14px
    line-height: 17px
    justify-content: space-between
    margin-bottom: 7px
  &__value
    background: linear-gradient(to right, #f76061 0%, #7babfa 50%, #a37afa 100%)
    border-radius: 30px
    height: 11px
    width: 100%
    position: relative
  &__circle
    position: absolute
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))
    width: 24px
    height: 24px
    top: 50%
    border-radius: 24px
    background: colors.$F7
    display: flex
    align-items: center
    justify-content: center
    transform: translate(-50%, -53%)
    &::before
      content: ''
      background: colors.$viol
      width: 12px
      height: 12px
      border-radius: 12px
  &__blurtrace
    position: absolute
    top: 50%
    height: 24px 
    transform: translateY(-50%)
    background: rgba(247, 247, 247, 0.8)
    backdrop-filter: blur(2.5px)
    border-radius: 24px
</style>