<template>
  <button class="violbtn" :class="{'violbtn_afterspace':afterspace}"><slot/></button>
</template>

<script>
export default {
  props: {
    afterspace: { type: Boolean, default: false }
  }
}
</script>

<style lang="sass" scoped>
.violbtn
  width: 100%
  font-family: fonts.$font1
  color: colors.$F7
  background: colors.$viol
  border-radius: 20px
  max-width: 500px
  margin-left: auto
  margin-right: auto
  font-size: 24px
  line-height: 29px
  text-align: center
  height: 56px
  cursor: pointer
  &_afterspace
    margin-bottom: 41px
</style>