<template>
  <div class="test test-bg">
    <div class="test__slides">
      <div ref="window" class="test__window" :style="`transform: translateX(-${step * 200}%)`">
        <slot />
      </div>
    </div>
    <Transition name="fade">
      <div class="test__message" :class="{ 'test__message_continue': message.type == 'continue' }" v-if="messageShown">
        <p class="test__message-text" :style="message.styles" v-html="message.content"></p>
        <ViolBtn v-if="message.type == 'try_again'" @click="closeMessage">Try again</ViolBtn>
        <ViolBtn v-if="message.type == 'continue'" @click="closeMessage">Continue</ViolBtn>
      </div>
    </Transition>
  </div>
</template>

<script>
import ViolBtn from './common/ViolBtn.vue'

export default {
  data: () => ({
    message: {},
    messageShown: false,
    onMessageClose: null
  }),
  props: {
    step: { type: Number, default: 0 },
  },
  methods: {
    showMessage(message, onMessageClose) {
      this.message = message;
      this.messageShown = true;
      this.onMessageClose = onMessageClose
    },
    closeMessage(){
      this.messageShown = false
      if (this.onMessageClose) {
        this.onMessageClose()
        this.onMessageClose = null
      }
    },

  },
  watch: {
    step() {
      this.$refs.window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  components: { ViolBtn }
}
</script>

<style lang="sass">
.test
  font-family: fonts.$font1
  background: colors.$F7
  display: flex
  flex-direction: column
  justify-content: space-between
  &__slides
    width: 100%
    max-width: 100%
    overflow: hidden
  &__window
    min-height: 100vh
    display: flex
    width: 100%
    min-width: 100%
    transition: transform .5s ease
    >*
      width: 100%
      min-width: 100%
      margin-right: 100%
      &:not(.active)
        max-height: 100vh
        overflow-y: scroll
        overflow-x: hidden
      &.descriptionslide.active + *
        overflow: visible !important

  &__message
    position: fixed
    z-index: 200
    background: rgba(61, 69, 72, 0.8)
    backdrop-filter: blur(5px)
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    padding: 41px 24px
    &_continue
      background: none
      backdrop-filter: initial
      .test__message-text
        color: black
        font-size: 18px
        line-height: 1.21
        padding: 60px 0 0
    &-text
      flex-grow: 1
      display: flex
      flex-direction: column
      justify-content: center
      padding: 0 29px
      font-weight: 500
      color: white
      font-size: 22px
      line-height: 27px

.test-bg
  .questionslide, .resultslide, .finalslide,.descriptionslide
    position: relative
    &::after, &::before
      content: ''
      display: block
      z-index: 1
      position: absolute
      top: 0
      left: calc(100% - 1px)
      width: calc(100% + 1px)
      height: 100%
      transform: scaleX(-1)
    &::before
      left: initial
      right: calc(100% - 1px)
  .descriptionslide::before
    display: none !important
  .questionslide, .resultslide, .finalslide, .descriptionslide
    &, &::after, &::before
      background: var(--background)
</style>