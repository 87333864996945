<template>
  <input class="inputfield inputfield__file" :ref="name" @change="handleFileUpload" :class="{'inputfield_error':error}" type="file" :placeholder="placeholder">
</template>

<script>
export default {
  data: ()=>({
    error: false
  }),
  props: {
    modelValue: { type: File, default: null },
    // type: { type: String, default: 'text' },
    required: { type: Boolean, default: false },
    placeholder: { type: String },
    // regex: { type: RegExp },
    // name: { type: String }
  },
  methods: {
    handleFileUpload(event){
     this.val = event.target.files[0]
    },
    isCorrect(v){
      if (v === undefined) v = this.val
      let res = true
      if (this.required && !v) res =  false
      if (this.regex && !(v.match(this.regex))) res = false
      this.error = !res
      return res
    },
  },
  computed: {
    val: {
      get(){
        return this.modelValue
      },
      set(v){
        this.$emit('update:modelValue',v)
        this.$emit('correct', this.isCorrect(v))
      }
    }
  }/*,
  actions: {
    clearFileInput ({state}) {
      this.val = null
    }
  }*/
}
</script>

<style lang="sass" scoped>
.inputfield
  padding: 12px 32px
  font-family: fonts.$font1
  font-size: 18px
  width: 100%
  line-height: 22px
  border-radius: 20px
  background: white
  color: black
  border: 1px solid white
  transition: border-color .2s ease-out
  outline: none
  &+&
    margin-top: 16px
  &:focus
    border-color: colors.$grayfont
  &_error
    border-color: colors.$coco
  &::placeholder
    color: colors.$grayfont
</style>