<template>
  <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.5312 5.46875L5.46875 19.5312" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.5312 19.5312L5.46875 5.46875" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> </svg>
</template>

<script>
  export default {
    
  }
</script>

<style lang="sass" scoped>
svg
  width: 25px
</style>