<template>
  <div class="panel-place" :class="{'hidden':hidden}"></div>
  <div class="panel" :class="{'hidden':hidden}">
    <div class="panel__btns">
      <button class="panel__btn" :class="{'active': mode == 0}" @click="$store.commit('setMode', 0); $router.push('/statistics')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill=currentColor xmlns="http://www.w3.org/2000/svg"> <path d="M14.9993 17.8125C18.6236 17.8125 21.5618 14.8744 21.5618 11.25C21.5618 7.62563 18.6236 4.6875 14.9993 4.6875C11.3749 4.6875 8.43677 7.62563 8.43677 11.25C8.43677 14.8744 11.3749 17.8125 14.9993 17.8125Z" fill="currentColor"/> <path d="M14.9993 0.937501C12.8758 0.936686 10.8039 1.59164 9.06681 2.81284C7.32967 4.03404 6.01204 5.7619 5.29401 7.76025C4.57597 9.75861 4.49258 11.9299 5.05523 13.9775C5.61787 16.025 6.7991 17.8489 8.43746 19.1997V28.125C8.43744 28.2848 8.47828 28.442 8.5561 28.5816C8.63391 28.7212 8.74613 28.8385 8.88208 28.9225C9.01802 29.0066 9.17319 29.0544 9.33285 29.0616C9.4925 29.0688 9.65133 29.035 9.79427 28.9635L14.9991 26.3607L20.2058 28.9636C20.3487 29.035 20.5075 29.0687 20.6671 29.0616C20.8268 29.0544 20.9819 29.0065 21.1179 28.9225C21.2538 28.8385 21.366 28.7211 21.4438 28.5815C21.5216 28.4419 21.5625 28.2848 21.5625 28.125V19.1986C23.2005 17.8475 24.3813 16.0237 24.9437 13.9762C25.506 11.9287 25.4224 9.75756 24.7042 7.75941C23.9861 5.76126 22.6684 4.03362 20.9314 2.81258C19.1943 1.59154 17.1226 0.936684 14.9993 0.937501V0.937501ZM14.9993 19.6875C13.3305 19.6875 11.6992 19.1926 10.3116 18.2655C8.9241 17.3384 7.84265 16.0206 7.20404 14.4789C6.56542 12.9371 6.39833 11.2406 6.72389 9.60393C7.04946 7.96721 7.85305 6.46379 9.03305 5.28379C10.2131 4.10378 11.7165 3.30019 13.3532 2.97462C14.9899 2.64906 16.6864 2.81615 18.2282 3.45477C19.7699 4.09338 21.0877 5.17484 22.0148 6.56238C22.9419 7.94992 23.4368 9.58122 23.4368 11.25C23.4342 13.487 22.5445 15.6316 20.9627 17.2134C19.3809 18.7952 17.2363 19.685 14.9993 19.6875V19.6875Z" fill="currentColor"/> </svg>
        <span>Statistics</span>
      </button>
      <button class="panel__btn" :class="{'active': mode == 1}" @click="$store.commit('setMode', 1); $router.push('/main')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.2449 5.16996C16.0169 3.90168 13.9829 3.90168 12.7549 5.16995L6.75676 11.3646C6.41228 11.7204 6.1808 12.1701 6.09148 12.6572C5.36303 16.6297 5.30926 20.6965 5.93242 24.6869L6.15305 26.0996C6.22274 26.5458 6.60707 26.8748 7.0587 26.8748H11.2499C11.5951 26.8748 11.8749 26.595 11.8749 26.2498V17.4998H18.1249V26.2498C18.1249 26.595 18.4047 26.8748 18.7499 26.8748H22.941C23.3927 26.8748 23.777 26.5458 23.8467 26.0996L24.0673 24.6869C24.6905 20.6965 24.6367 16.6297 23.9083 12.6572C23.8189 12.1701 23.5875 11.7204 23.243 11.3646L17.2449 5.16996Z" fill="currentColor"/> </svg>
        <span>Home</span>
      </button>
      <button class="panel__btn" :class="{'active': mode == 2}" @click="$store.commit('setMode', 2); $router.push('/profile')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill=currentColor xmlns="http://www.w3.org/2000/svg"> <path d="M15 19.2188C17.8477 19.2188 20.1562 16.9102 20.1562 14.0625C20.1562 11.2148 17.8477 8.90625 15 8.90625C12.1523 8.90625 9.84375 11.2148 9.84375 14.0625C9.84375 16.9102 12.1523 19.2188 15 19.2188Z" fill="currentColor"/> <path d="M15 2.8125C12.5895 2.8125 10.2332 3.52728 8.22899 4.86646C6.22477 6.20564 4.66267 8.10907 3.74022 10.336C2.81778 12.563 2.57643 15.0135 3.04668 17.3777C3.51694 19.7418 4.67769 21.9134 6.38214 23.6179C8.08659 25.3223 10.2582 26.4831 12.6223 26.9533C14.9865 27.4236 17.437 27.1822 19.664 26.2598C21.8909 25.3373 23.7944 23.7752 25.1335 21.771C26.4727 19.7668 27.1875 17.4105 27.1875 15C27.1838 11.7688 25.8986 8.67098 23.6138 6.38617C21.329 4.10137 18.2312 2.81616 15 2.8125ZM22.706 21.8449C21.9534 20.7546 20.9775 19.8368 19.843 19.1526C18.5385 20.3985 16.8039 21.0937 15 21.0937C13.1961 21.0937 11.4615 20.3985 10.157 19.1526C9.0225 19.8368 8.04655 20.7546 7.29395 21.8449C5.97267 20.3592 5.10906 18.5232 4.8071 16.558C4.50514 14.5928 4.77771 12.5822 5.592 10.7684C6.40628 8.95451 7.72755 7.41471 9.39671 6.3344C11.0659 5.25408 13.0117 4.67932 15 4.67932C16.9882 4.67932 18.9341 5.25408 20.6033 6.3344C22.2724 7.41471 23.5937 8.95451 24.408 10.7684C25.2223 12.5822 25.4948 14.5928 25.1929 16.558C24.8909 18.5232 24.0273 20.3592 22.706 21.8449Z" fill="currentColor"/></svg>
        <span>Profile</span>
      </button>
    </div>
    <div class="panel__line"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    props: {
      hidden: { type: Boolean, default: false}
    },
    computed: {
      ...mapState(['mode'])
    }
  }
</script>

<style lang="sass" scoped>
.panel-place
  height: 84px
  &.hidden
    height: 0px

.panel
  position: fixed
  bottom: 0
  width: 100%
  height: 84px
  border: 1px solid colors.$EB
  border-radius: 30px 30px 0px 0px
  background: white
  overflow: hidden
  transition: height .3s ease-out
  &.hidden
    height: 0px
  &__btns
    display: flex
    justify-content: space-between
    width: calc(100% - 72px)
    margin: 0 auto 4px
  &__btn
    flex-basis: 25.1%
    padding: 10px
    cursor: pointer
    transition: color .3s ease-out
    color: colors.$silver
    font-weight: 500
    font-size: 12px
    line-height: 13px
    font-family: fonts.$font2
    &.active
      color: colors.$viol
  &__line
    margin: 0 auto 8px
    border-radius: 100px
    background: colors.$silver
    height: 5px
    width: 134px
</style>