<template>
  <Test :step="step" @next="toNext" @back="step--" class="test-bg_0">
    <component v-for="(slide, index) in slides" 
    :key="index" 
    :is="`${slide.type}Slide`" 
    v-bind="propsToPass(slide)"
    :class="{'active': index == step}"
    @setAnswer="slide.answer = $event"
    :style="slide.background?`--background: ${slide.background}`:null"
    @setScore="setScore"  />
  </Test>
</template>

<script>
import Test from '@/components/Test.vue';
import DescriptionSlide from '@/components/testSlides/descriptionSlide.vue';
import FinalSlide from '@/components/testSlides/finalSlide.vue';
import QuestionSlide from '@/components/testSlides/questionSlide.vue';
import ResultSlide from '@/components/testSlides/resultSlide.vue';
import StartSlide from '@/components/testSlides/startSlide.vue';
import { mapState } from 'vuex';

let timestampStart = Date.now()

export default {
  components: { Test, StartSlide, DescriptionSlide, QuestionSlide, ResultSlide, FinalSlide },
  async mounted() {
    if (!this.slides.length) {
      while (this.mockEnabled && !this.fetchReplaced)
          await new Promise(r => setTimeout(r, 100))
      let firstSlide = await this.getSlide(0)
      if (firstSlide)
        this.slides.push(firstSlide)
    }
  },
  data: () => ({
    step: 0,
    slides: [],
    nexting: false
  }),
  methods: {
    async toNext() {
      if (this.nexting) return
      this.nexting = true
      if (this.step == this.slides.length - 1) {
        let nextSlide = await this.getSlide(this.step + 1)
        if (nextSlide) {
          console.log(nextSlide)
          this.slides.push(nextSlide)
          this.step++
          timestampStart = Date.now()
        }
      } else
        this.step++
      this.nexting = false
    },
    async getSlide(slide) {
      if (this.answers[this.step]) {
        this.slides[this.step].answerTime = Math.ceil((Date.now() - timestampStart) / 1000)
      }

      let result = await fetch(this.$store.state.serverUrl + '/tests/data', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ test: this.testId, slide: slide, answers: this.answers, answerTimes: this.answerTimes })
      }).then(res => {
        if (!res.ok) throw ('getSlide: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      if (result.status == 'ok') {
        return result.slide_data
      }
      else {
        if (result.redirect_url) {
          this.$router.push(result.redirect_url)
          return
        }

        alert(result.message)
        return null
      }
    },
    setScore(score) {
      console.log(score)
    },
    propsToPass(slide) {
      let res = {}
      for (let key in slide) {
        if (key == 'type') continue
        res[key] = slide[key]
      }
      return res
    }
  },
  computed: {
    answers() {
      return this.slides.map(el => el.answer)
    },
    answerTimes() {
      return this.slides.map(el => el.answerTime)
    },
    testId(){
      return this.$route.params.id
    },
    ...mapState(['serverUrl','mockEnabled','fetchReplaced'])
  },
  watch: {
    async testId(v, old) {
      if (v != old) {
        this.step = 0
        this.slides = []
        let firstSlide = await this.getSlide(0)
        if (firstSlide)
          this.slides.push(firstSlide)
      }
    }
  }
}
</script>

<style lang="sass">
</style>