<template>
  <div class="rinput">
    <svg width="0" height="0" style="position: absolute; opacity: 0; pointer-events: none;">
      <symbol viewBox="0 0 42 42" id="star">
        <path d="M22.0896 5.12211C21.4308 3.98685 19.7914 3.98684 19.1326 5.1221L14.4934 13.1172C14.168 13.678 13.6055 14.0607 12.9644 14.1575L4.27021 15.4707C2.80995 15.6913 2.29773 17.535 3.43497 18.4771L9.9118 23.8431C10.5113 24.3399 10.7932 25.1234 10.6476 25.8882L8.95336 34.7855C8.68553 36.192 10.162 37.2848 11.4289 36.6177L19.6156 32.3071C20.2387 31.979 20.9835 31.979 21.6066 32.3071L29.7933 36.6177C31.0602 37.2848 32.5367 36.192 32.2689 34.7855L30.5746 25.8882C30.429 25.1234 30.7109 24.3399 31.3104 23.8431L37.7873 18.4771C38.9245 17.535 38.4123 15.6913 36.952 15.4707L28.2578 14.1575C27.6167 14.0607 27.0542 13.678 26.7288 13.1172L22.0896 5.12211Z"></path>
      </symbol>
    </svg>
    <div class="rinput__line" :data-score="score">
      <svg viewBox="0 0 42 42" role="button" :class="{'active':score > 0}" @click="score = 1">
        <use xlink:href="#star"></use>
      </svg>
      <svg viewBox="0 0 42 42" role="button" :class="{'active':score > 1}" @click="score = 2">
        <use xlink:href="#star"></use>
      </svg>
      <svg viewBox="0 0 42 42" role="button" :class="{'active':score > 2}" @click="score = 3">
        <use xlink:href="#star"></use>
      </svg>
      <svg viewBox="0 0 42 42" role="button" :class="{'active':score > 3}" @click="score = 4">
        <use xlink:href="#star"></use>
      </svg>
      <svg viewBox="0 0 42 42" role="button" :class="{'active':score > 4}" @click="score = 5">
        <use xlink:href="#star"></use>
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    data: ()=>({
      score: 0
    }),
    watch: {
      score(v){
        this.$emit('setScore', v)
      }
    }
  }
</script>

<style lang="sass" scoped>
.rinput
  &__line
    width: 226px
    max-width: 100%
    display: flex
    svg
      display: block
      flex-grow: 1
      fill: colors.$E7
      &:not(:last-child)
        margin-right: 5px
      &.active
        fill: colors.$gold
</style>