<template>
  <router-view/>
</template>

<script>
export default{
  beforeCreate() {
    if (this.$router.options.history.state.current == '/') {
      this.$router.push('/main')
    }
  },
  async created() {
    this.$store.commit('setServerUrl', window.location.origin + '/api')
    // this.$store.commit('setServerUrl', window.location.origin + '/')
    if (this.$store.state.mockEnabled) {
      let fakeServerRequest = (await import(`@/store/fakeServerRequest.js`)).default
      window.fetch = async (url, options) => {
        return fakeServerRequest(url, options)
      }
      this.$store.commit('setServerUrl', 'server_url')
      this.$store.commit('setFetchReplaced', true)
    }
  }
}
</script>

<style lang="sass">
.fade-enter-active,.fade-leave-active 
  transition: opacity 0.4s ease

.fade-enter-from,.fade-leave-to 
  opacity: 0

.swiper-container
  margin-left: auto
  margin-right: auto
  position: relative
  overflow: hidden
  list-style: none
  padding: 0
  z-index: 1

.swiper-wrapper
  position: relative
  width: 100%
  height: 100%
  z-index: 1
  display: flex
  transition-property: transform
  box-sizing: content-box

.swiper-container-android .swiper-slide, .swiper-wrapper
  transform: translate3d(0px, 0, 0)

.swiper-container-pointer-events
  touch-action: pan-y

.swiper-slide
  flex-shrink: 0
  width: 100%
  height: 100%
  position: relative
  transition-property: transform

.swiper-slide-invisible-blank
  visibility: hidden

</style>
