<template>
  <div class="onboarding">
    <div class="onboarding__slides">
      <div class="onboarding__slides-flex" :style="`transform: translateX(-${(step - 1) * 200}%)`">
        <OnboardSlide class="onboarding__slide" title="Welcome to Deeper!" subtitle="We can help you to guide you toward a happier and healthier life!" :img="require(`@/assets/img/onboarding/1.svg`)"/>
        <OnboardSlide class="onboarding__slide" title="Understand yourself" subtitle="Play situational games and see statistics about yourself" :img="require(`@/assets/img/onboarding/2.svg`)"/>
        <OnboardSlide class="onboarding__slide" title="Understand your feelings" subtitle="Understand your emotions, actions and consequences" :img="require(`@/assets/img/onboarding/3.svg`)"/>
        <OnboardSlide class="onboarding__slide" title="Improve yourself" subtitle="Improve your skills and qualities based on statistics" :img="require(`@/assets/img/onboarding/4.svg`)"/>
      </div>
    </div>
    <div class="onboarding__constant">
      <div class="onboarding__lines">
        <div v-for="i in stepcount" :key="i" @click="step = i" class="onboarding__line" :class="{'active': step == i}"></div>
      </div>
      <div class="onboarding__btnwrap">
        <button class="onboarding__continue" @click="step++">
          <span>Continue</span>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.96967 3.96967C7.26256 3.67678 7.73744 3.67678 8.03033 3.96967L12.5303 8.46967C12.8232 8.76256 12.8232 9.23744 12.5303 9.53033L8.03033 14.0303C7.73744 14.3232 7.26256 14.3232 6.96967 14.0303C6.67678 13.7374 6.67678 13.2626 6.96967 12.9697L10.9393 9L6.96967 5.03033C6.67678 4.73744 6.67678 4.26256 6.96967 3.96967Z" fill="black"/> </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardSlide from '../components/OnboardSlide.vue';

export default {
  data: ()=>({
    step: 1,
    stepcount: 4
  }),
  components: { OnboardSlide }
}
</script>

<style lang="sass">
.onboarding
  font-family: fonts.$font1
  height: 100vh
  overflow: hidden
  display: flex
  flex-direction: column
  color: colors.$mainfont
  background: colors.$F7
  &__slides
    flex-basis: 655px
    flex-shrink: 1
    flex-grow: 2
    width: 100%
    overflow: hidden
    &-flex
      height: 100%
      width: 100%
      display: flex
      transition: transform .5s ease
      >*
        width: 100%
        min-width: 100%
        margin-right: 100%
  &__constant
    flex-basis: 155px
    flex-grow: 1
    flex-shrink: 5
    display: flex
    flex-direction: column
  &__lines
    flex-shrink: 1
    flex-grow: 1.288
    min-height: 30px
    display: flex
    align-items: flex-start
    justify-content: center
  &__btnwrap
    flex-grow: 1
    min-height: 50px
    display: flex
    align-items: flex-start
    flex-shrink: 1
    justify-content: center
  &__line
    &:not(:last-child)
      margin-right: 9px
    flex-shrink: 1
    flex-basis: 50px
    background: colors.$silver
    cursor: pointer
    height: 2px
    &.active
      background: colors.$viol
  &__continue
    font-size: 18px
    line-height: 22px
    display: flex
    align-items: center
    cursor: pointer

</style>