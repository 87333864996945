<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <GridTile :testid="3" :img="require('@/assets/img/profile/grid/1.png')" title="Long awaited meeting" />
      </div>
      <div class="swiper-slide">
        <GridTile :testid="5" :img="require('@/assets/img/profile/grid/2.png')" title="Romance is dead" />
      </div>
      <div class="swiper-slide">
        <GridTile :testid="3" :img="require('@/assets/img/profile/grid/1.png')" title="Long awaited meeting" />
      </div>
    </div>
  </div>
</template>

<script>
import GridTile from './GridTile.vue'

export default {
  components: { GridTile },
  mounted() {
    //eslint-disable-next-line
    new Swiper(this.$el, {
      slidesPerView: 2,
      spaceBetween: 9,
      speed: 300,
      breakpoints: {
        1: {
          slidesPerView: 1,
        },
        349: {
          slidesPerView: 2,
        },
        513: {
          slidesPerView: 3,
        },
        677: {
          slidesPerView: 4,
        },
        841: {
          slidesPerView: 5,
        },
        1005: {
          slidesPerView: 6,
        },
        1169: {
          slidesPerView: 7,
        },
        1333: {
          slidesPerView: 8,
        },
        1497: {
          slidesPerView: 9,
        },
        1661: {
          slidesPerView: 10,
        },
      }
    });
  }
}
</script>

<style lang="sass" scoped>
.swiper-container, .swiper-wrapper
  overflow: visible !important

</style>