<template>
  <div class="register">
    <div class="register__slides">
      <div class="register__slides-flex" :style="`transform: translateX(-${(step - 1) * 200}%)`">
        <div class="fieldslide">
          <div class="fieldslide__img">
            <img :src="require('@/assets/img/login/login.svg')">
          </div>
          <div class="fieldslide__title fieldslide__title_3 fieldslide__title-big">
            Welcome back
          </div>
          <InputField v-model="slides[0].email" :required="true" ref="email" placeholder="E-mail" />
          <InputField v-model="slides[0].password" :required="true" ref="password" type="password" placeholder="Password"/>
        </div>
      </div>
    </div>
    <div class="register__constant">
      <div class="register__btnwrap">
        <ViolBtn @click="toStep" :required="true">Continue</ViolBtn>
      </div>
<!--      <div class="register__socials" :class="{'hidden': step > 1}">
        <Social type="facebook"/>
        <Social type="google"/>
        <Social type="twitter"/>
      </div>-->
      <div class="register__text" :class="{'hidden': step > 1}">
        Don't have an account yet? <br>
        <a class="register__text-viol register__link" @click="$router.push('/register')">Sign up</a>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '../components/common/InputField.vue';
// import Social from '../components/common/Social.vue';
import ViolBtn from '../components/common/ViolBtn.vue';


export default {
    data: () => ({
        step: 1,
        stepcount: 1,
        slides: [{
          email: '',
          password: ''
        }]
    }),
    async beforeCreate() {
      let result = await fetch(this.$store.state.serverUrl + '/login', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      }).then(res => {
        if (!res.ok) throw ('login: not_ok')
        return res.json()
      }).catch(err => {
        console.error(err)
        return null
      })

      if (result.status != 'ok') {
        if (result.redirect_url) {
          this.$router.push(result.redirect_url)
          return
        }

        alert(result.message)
        return null
      }
    },
    components: { InputField, ViolBtn/*, Social*/ },
    methods: {
      async toStep(){
        let step = this.step - 1
        let ok = true
        for (let key in this.slides[step])
        ok *= this.$refs[key].isCorrect()
        if (this.step == this.stepcount) {
          let result = await this.login()

          console.log('result_data:')
          console.log(result)

          if (result.status == 'ok') {
            let prevPath = this.$router.options.history.state.back

            if (this.$router.options.history.state.back && !(['/login', '/register'].includes(prevPath)))
              this.$router.push(prevPath)
            else
              this.$router.push('/profile')
          }
          else {
            alert(result.message)
          }

          return
        }
        if (ok) this.step++        
      },
      async login() {
        let formData = {};
        for (let item of this.slides) {
          for (let key in item) {
            formData[key] = item[key]
          }
        }
        console.log('login_form_data:')
        console.log(formData)

        return await fetch(this.$store.state.serverUrl + '/auth/login', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }).then(res => {
          if (!res.ok) throw ('login: not_ok')
          return res.json()
        }).catch(err => {
          console.error(err)
          return null
        })
      }
    }
}
</script>

<style lang="sass">
.fieldslide
  &__title
    &_3
      margin-top: -45px
.register__link
  cursor: pointer
</style>