<template>
  <div class="questionslide">
    <SlideHead :progress="progress" @back="$parent.$emit('back')" />
    <div class="questionslide__content" :class="{'questionslide__content_hidden': hidden}">
      <div>
        <div class="questionslide__text" v-html="question.text"></div>
        <div class="questionslide__question" v-html="question.question"></div>
        <div class="questionslide__questions">
          <SlideQuestion :locked="locked" v-for="(v,i) in question.variants" :key="i" :variant="v" :name="question.name" :modelValue="answer" @update:modelValue="$emit('setAnswer', $event)"/>
        </div>
      </div>
      <ViolBtn class="questionslide__btn" @click="Btnclick">Continue</ViolBtn>
    </div>
  </div>
</template>

<script>
import SlideHead from '../common/slideHead.vue';
import SlideQuestion from '../common/slideQuestion.vue';
import ViolBtn from '../common/ViolBtn.vue';

export default {
  components: { ViolBtn, SlideHead, SlideQuestion },
  data: ()=>({
    locked: false,
    hidden: false
  }),
  props: {
    question: { type: Object, required: true },
    progress: { type: Number, required: true },
    answer: { type: String, default: '' }
  },
  computed: {
    message(){
      let ans = this.answer
      if (!ans) return false
      let variant = this.question.variants.find(v=>v.value == ans)
      if (!variant) console.error('Вариант не найден')
      return variant.message
    }
  },
  methods: {
    Btnclick(){
      if (this.message) {
        if (this.message.type == 'try_again')
          this.$parent.showMessage(this.message)
        else {
          this.$el.scrollTo({
            top: 0,
            behavior: "smooth"
          })
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          })
          this.$parent.showMessage(this.message, this.LockAnswerAndNext)
          this.hidden = true
        }
      }
      else 
        if (this.answer) 
          this.LockAnswerAndNext()
    },
    LockAnswerAndNext(){
      this.locked=true
      this.$parent.$emit('next')
      this.hidden = false
    }
  }
}
</script>

<style lang="sass">
.questionslide
  font-family: fonts.$font1
  min-height: 100vh
  display: flex
  flex-direction: column
  box-sizing: content-box

  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    flex-grow: 1
    padding: 1px 24px 41px
    font-size: 18px
    line-height: 22px
    transition: opacity .3s ease-out
    &_hidden
      opacity: 0
      pointer-events: none
  &__question
    font-family: fonts.$font1
    margin: 12px 0 27px
    color: colors.$grayfont
  &__btn
    margin-top: 24px
</style>